import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Author from "../components/Author/Author"
import PostNavigation from "../components/PostNavigation/PostNavigation"
import SocialBlock from "../components/SocialBlock/SocialBlock"
import MetaPost from "../components/MetaPost/MetaPost"
import PostContent from "../components/PostContent/PostContent"
import "./BlogPost.scss"
import Button from "../components/Button/Button"
import Modal from "../components/Modal"
import axios from "axios"
import { toast } from "react-toastify"
import SpinnerLoader from "../components/Loader/SpinnerLoader"
import CONSTANT_INFO from "../constant/constant"
import Newsletter from "../components/Form/Newsletter"

export default function BlogPost({ data, pageContext, location }) {
    const { allMarkdownRemark, post } = data
    const { author, prev, next } = pageContext
    const [modalOpen, setModalOpen] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [isAgree, setIsAgree] = useState(false)

    const blogAuthor =
        allMarkdownRemark?.nodes?.find(node => {
            return (
                node?.frontmatter?.authorName &&
                node?.frontmatter?.authorName === author?.nodes[0]?.name
            )
        }) || {}

    const handleToogleModal = action => {
        setModalOpen(action)
    }

    const handleToogleAgree = e => {
        setIsAgree(!isAgree)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoadingForm(true)
        const formData = new FormData(e.target)
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const fields = [
            {
                name: "firstname",
                value: formData.get("name")
            },
            {
                name: "email",
                value: formData.get("email")
            },
            {
                name: "company",
                value: formData.get("company")
            },
            {
                name: "phone",
                value: formData.get("phone")
            }
        ]
        await axios
            .post(
                CONSTANT_INFO.curl.hubspot_form,
                {
                    portalId: process.env.GATSBY_HUBSPOT_PORTAL,
                    formGuid: process.env.GATSBY_HUBSPOT_FORM_GUID,
                    fields
                },
                config
            )
            .then(() => {
                setModalOpen(false)
                const resourceFileUrl =
                    data?.post?.resource_file?.downloadUrl?.mediaItemUrl
                window.open(resourceFileUrl, "_blank")
            })
            .catch(() => {
                toast.error("Error occur, please try again")
            })
            .finally(() => {
                setLoadingForm(false)
                setIsAgree(false)
            })
    }
    const context = {
        pageName: "SD | Resources",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <section className="py-2 lg:py-0">
                <div className="container-blog-content mt-10">
                    <div className="mx-auto max-w-[740px]">
                        <MetaPost blogAuthor={blogAuthor} date={post?.date} />
                        <h1 className="mb-6 px-5 text-[1.875rem] font-bold leading-[2.25rem]">
                            {post?.title}
                        </h1>
                        {post?.content && (
                            <PostContent content={post.content} />
                        )}
                    </div>
                </div>
                <div className="download-section sm:mb-20">
                    {/* <Modal/> */}
                    <Button
                        type="gray"
                        className="mx-auto"
                        onClick={() => handleToogleModal(true)}
                    >
                        DOWNLOAD This file
                    </Button>
                </div>
                <Newsletter context={context} />
            </section>
            <section>
                <div className="blog-post-container container">
                    <div className="mx-auto max-w-[740px]">
                        <div className="social-icon grid grid-cols-1 sm:my-4">
                            <SocialBlock />
                            {(next || prev) && (
                                <PostNavigation next={next} prev={prev} />
                            )}
                        </div>
                        <Author blogAuthor={blogAuthor} />
                    </div>
                </div>
            </section>
            <Modal
                isOpen={modalOpen}
                handleClose={() => handleToogleModal(false)}
            >
                <div className="flex flex-wrap items-center justify-between gap-10 lg:flex-nowrap xl:gap-80">
                    <div>
                        <h1 className="font-bold sm:text-5xl">Download</h1>
                    </div>
                    <div>
                        <p>
                            Drop us a message blow and we will get back to you
                            to discuss your next project.
                        </p>
                    </div>
                </div>
                <div className="mt-10 lg:mt-36">
                    <form onSubmit={handleSubmit}>
                        <div className="grid md:grid-cols-2 md:gap-x-6 lg:gap-x-[30px]">
                            <div className="group relative z-0 mb-6 w-full lg:mb-[87px]">
                                <input
                                    type="text"
                                    name="name"
                                    className="peer block w-full appearance-none border-0 border-b-2 border-eerie-black border-opacity-40 bg-transparent py-2.5 px-0 text-sm text-eerie-black placeholder-transparent focus:border-primary-color focus:outline-none focus:ring-0 md:text-base"
                                    placeholder="name"
                                    required
                                />
                                <label
                                    htmlFor="name"
                                    className="absolute top-0 -z-10 origin-[0] -translate-y-6 transform text-sm leading-8 text-eerie-black opacity-40 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-90 peer-focus:font-medium peer-focus:text-primary-color md:text-base"
                                >
                                    Name
                                    <span className="text-red-500">(*)</span>
                                </label>
                            </div>
                            <div className="group relative z-0 mb-6 w-full lg:mb-[87px]">
                                <input
                                    type="email"
                                    name="email"
                                    className="peer block w-full appearance-none border-0 border-b-2 border-eerie-black border-opacity-40 bg-transparent py-2.5 px-0 text-sm text-eerie-black placeholder-transparent focus:border-primary-color focus:outline-none focus:ring-0 md:text-base"
                                    placeholder="email"
                                    required
                                    pattern="\S+@\S+\.\S+"
                                    // pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                                />
                                <label
                                    htmlFor="email"
                                    className="absolute top-0 -z-10 origin-[0] -translate-y-6 transform text-sm text-eerie-black opacity-40 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-90 peer-focus:font-medium peer-focus:text-primary-color md:text-base"
                                >
                                    Email
                                    <span className="text-red-500">(*)</span>
                                </label>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="group relative z-0 mb-6 w-full  lg:mb-[87px]">
                                <input
                                    type="text"
                                    name="company"
                                    className="peer block w-full appearance-none border-0 border-b-2 border-eerie-black border-opacity-40 bg-transparent py-2.5 px-0 text-sm text-eerie-black placeholder-transparent focus:border-primary-color focus:outline-none focus:ring-0 md:text-base"
                                    placeholder="company"
                                />
                                <label
                                    htmlFor="company"
                                    className="absolute top-0 -z-10 origin-[0] -translate-y-6 transform text-sm text-eerie-black opacity-40 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-90 peer-focus:font-medium peer-focus:text-primary-color md:text-base"
                                >
                                    Company
                                </label>
                            </div>
                            <div className="group relative z-0 mb-6 w-full  lg:mb-[87px]">
                                <input
                                    type="tel"
                                    // pattern="[0-9]"
                                    name="phone"
                                    className="peer block w-full appearance-none border-0 border-b-2 border-eerie-black border-opacity-40 bg-transparent py-2.5 px-0 text-sm text-eerie-black placeholder-transparent focus:border-primary-color focus:outline-none focus:ring-0 md:text-base"
                                    placeholder="phone"
                                />
                                <label
                                    htmlFor="phone"
                                    className="absolute top-0 -z-10 origin-[0] -translate-y-6 transform text-sm text-eerie-black opacity-40 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-90 peer-focus:font-medium peer-focus:text-primary-color md:text-base"
                                >
                                    Phone
                                </label>
                            </div>
                        </div>
                        <div
                            className={`${
                                loadingForm || !isAgree
                                    ? "pointer-events-none"
                                    : ""
                            } mt-5 lg:mt-0`}
                        >
                            <Button
                                type="blue"
                                btnType="submit"
                                // hasArrow={!loadingForm}
                                className={`${
                                    loadingForm || !isAgree
                                        ? "!bg-gray-400"
                                        : ""
                                }`}
                            >
                                Download your RESOURCE
                                {loadingForm && <SpinnerLoader />}
                            </Button>
                        </div>
                        <div
                            onClick={handleToogleAgree}
                            className="mt-10 lg:mt-20"
                        >
                            <input
                                checked={isAgree}
                                type="radio"
                                className="mr-2 align-middle transition-all"
                            />
                            <label className="font-semibold" for="note">
                                Subscribe to Saigon Digital Blog.
                            </label>
                        </div>
                        <p className="mt-16">
                            We're committed to your privacy. Saigon Digital uses
                            the information you provide to us to contact you
                            about our relevant content, products, and services.
                            You may unsubscribe from these communications at any
                            time.
                        </p>
                    </form>
                </div>
            </Modal>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ($id: String!) {
        post: wpPost(id: { eq: $id }) {
            title
            id
            slug

            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    sourceUrl
                    altText
                }
            }
            content
            date(formatString: "MMMM DD, YYYY")
            resource_file {
                downloadUrl {
                    mediaItemUrl
                }
            }
            seo {
                seoTitle
                seoDescription
            }
        }

        allMarkdownRemark {
            nodes {
                frontmatter {
                    title
                    authorName
                    avatar {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    avatarAlt {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                html
            }
        }
    }
`
export const Head = ({ data }) => {
    const { seoTitle, seoDescription } = data?.post?.seo
    const { featuredImage } = data?.post
    return (
        <SEO
            title={seoTitle + "| Saigon Digital"}
            description={seoDescription}
            image={featuredImage?.node?.sourceUrl}
        />
    )
}
